import React from 'react';
import classNames from 'classnames';
import './SubText.scss';
function SubText({ value, children, type, centered }) {
    const classes = classNames('sub-text', { [`sub-text--${type}`]: type });
    return (React.createElement("div", { className: classes },
        children,
        type !== null && (React.createElement("span", { className: classNames({
                'sub-text__string': true,
                'sub-text--centered': centered,
            }) }, value))));
}
export default SubText;
